import { addPropertyControls, ControlType } from "framer"
import React from "react"

export default function InlineImages(props) {
    const flipAnimation = (direction, delay) => ({
        width: props.imageWidth,
        height: props.imageWidth,
        position: "relative",
        transformStyle: "preserve-3d",
        animation: `${direction}Flip 24s ${delay}s infinite ease-in-out`,
    })

    const imageStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "absolute",
        backfaceVisibility: "hidden",
    }

    return (
        <h2
            style={{
                ...props.font,
                color: props.textColor,
                margin: 0,
                padding: 0,
                display: "inline-block",
                verticalAlign: "middle",
                fontWeight: 400,
            }}
        >
            Make your{" "}
            <span
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    perspective: "1000px",
                }}
            >
                <div style={flipAnimation("horizontal", 0)}>
                    <img
                        src={props.image1.src}
                        alt={props.image1.alt}
                        style={imageStyle}
                    />
                    <img
                        src={props.image1Flip.src}
                        alt={props.image1Flip.alt}
                        style={{ ...imageStyle, transform: "rotateY(180deg)" }}
                    />
                </div>
            </span>{" "}
            mark, and transform the way businesses{" "}
            <span
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    perspective: "1000px",
                }}
            >
                <div style={flipAnimation("vertical", 8)}>
                    <img
                        src={props.image2.src}
                        alt={props.image2.alt}
                        style={imageStyle}
                    />
                    <img
                        src={props.image2Flip.src}
                        alt={props.image2Flip.alt}
                        style={{ ...imageStyle, transform: "rotateX(180deg)" }}
                    />
                </div>
            </span>{" "}
            engage.
        </h2>
    )
}

addPropertyControls(InlineImages, {
    font: {
        type: "font",
        controls: "extended",
    },
    textColor: {
        type: ControlType.Color,
    },
    image1: {
        type: ControlType.ResponsiveImage,
        title: "Image 1",
    },
    image1Flip: {
        type: ControlType.ResponsiveImage,
        title: "Image 1 (Flipped)",
    },
    image2: {
        type: ControlType.ResponsiveImage,
        title: "Image 2",
    },
    image2Flip: {
        type: ControlType.ResponsiveImage,
        title: "Image 2 (Flipped)",
    },
    imageWidth: {
        type: ControlType.Number,
        title: "Image size",
        defaultValue: 30,
        min: 10,
        max: 200,
        unit: "px",
    },
})

/* CSS */
const style = document.createElement("style")
style.innerHTML = `
@keyframes horizontalFlip {
    0%, 25% {
        transform: rotateY(0deg);
    }
    30%, 55% {
        transform: rotateY(180deg);
    }
    60%, 100% {
        transform: rotateY(0deg);
    }
}

@keyframes verticalFlip {
    0%, 25% {
        transform: rotateX(0deg);
    }
    30%, 55% {
        transform: rotateX(180deg);
    }
    60%, 100% {
        transform: rotateX(0deg);
    }
}

@keyframes diagonalFlip {
    0%, 25% {
        transform: rotateY(0deg) rotateZ(0deg);
    }
    30%, 55% {
        transform: rotateY(180deg) rotateZ(180deg);
    }
    60%, 100% {
        transform: rotateY(0deg)rotateZ(0deg);
    }
}
`
document.head.appendChild(style)
